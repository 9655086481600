import Link from '@mui/material/Link';
import MailSvg from 'assets/remix-icons/line/business/global-line.svg';
import ContactSvg from 'assets/remix-icons/line/device/phone-line.svg';
import React from 'react';

interface Props {
  footerText?: string;
}
function GreyScaleContacts(props: Props) {
  const { footerText } = props;
  return (
    <>
      {footerText ? (
        <p> {footerText}</p>
      ) : (
        <>
          <img src={MailSvg} alt="mail to" className="icon" style={{ padding: '0.5rem' }}></img>
          <Link
            href="mailto:service@greyscaleai.com"
            style={{
              marginRight: '15px',
              color: '#FF2A00',
              textDecorationColor: '#FF2A00',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            service@greyscaleai.com
          </Link>
          <img
            src={ContactSvg}
            alt="contact at"
            className="icon"
            style={{ padding: '0.5rem' }}
          ></img>
          <Link
            href="tel:+16502327974"
            underline="none"
            style={{ color: '#000000', fontSize: '13px' }}
          >
            (650) 232-7974
          </Link>
        </>
      )}
    </>
  );
}

export default GreyScaleContacts;
