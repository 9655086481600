import Drawer from '@mui/material/Drawer';
import React from 'react';

import LeftNav from '../leftnav';

const drawerWidth = '100%';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
}

export default function ResponsiveDrawer(props: Props) {
  const { window, drawerOpen, handleDrawerToggle } = props;
  const drawer = (
    <div>
      <LeftNav handleDrawerToggle={handleDrawerToggle} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      container={container}
      anchor="top"
      variant="temporary"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
      }}
    >
      {drawer}
    </Drawer>
  );
}
