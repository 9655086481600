import notFoundImg from 'assets/Group 10752.svg';
import GreyScaleContacts from 'components/error-handlers/greyscale-contacts';
import React from 'react';

import './not-found.scss';

interface Props {
  headerText?: string;
  body?: string[];
  footerText?: string;
  children?: React.ReactNode;
}
function NotFound(props: Props) {
  const { headerText = 'Something went wrong!', footerText } = props;
  const bodyNode = props.children as any;
  // console.log('bodyNode', bodyNode);
  return (
    <main
      className="not-found-page"
      style={{
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <section>
        <img src={notFoundImg} alt="404 - resource not found" style={{ padding: '0.5rem' }}></img>
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p style={{ fontSize: '18px', fontWeight: '500' }}>{headerText}</p>
        {bodyNode ? (
          bodyNode
        ) : (
          <>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              Please try again in some time.
            </p>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              If the Problem persists, reach out us on
            </p>
          </>
        )}

        <address
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontStyle: 'normal'
          }}
        >
          <GreyScaleContacts footerText={footerText} />
        </address>
      </section>
    </main>
  );
}

export function NoExports(props: Props) {
  const bodyNode = props.children as any;
  // console.log('bodyNode', bodyNode);
  return (
    <main
      className="not-found-page"
      style={{
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {bodyNode ? (
          bodyNode
        ) : (
          <>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              No Exports to display
            </p>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              You can Export data from Inspections Tab
            </p>
          </>
        )}
      </section>
    </main>
  );
}

export default NotFound;
