import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import insightsConfig, { TuiConfigs, uiConfigs } from '../config/config';

// Constants for the retry logic
const MAX_RETRY = 3;
const RETRY_DELAY = 5000;

interface AuthDetails {
  isLoading: boolean;
  userSession: any;
  uiConfigs: TuiConfigs;
}

const initialState: AuthDetails = {
  isLoading: true,
  userSession: null,
  uiConfigs: uiConfigs
};

interface IAuthContext {
  authDetails: AuthDetails;
  handleAuth: ({ payload }: { payload: any }) => void;
  updateUiConfigFnc: ({ payload }: { payload: any }) => void;
  refreshTokenIfNeeded: () => Promise<void>;
}

interface IUpdateUiConfigFnc {
  payload: {
    data: Record<string, any>;
    event: string;
  };
}

const AuthContext = createContext<IAuthContext>({
  authDetails: initialState,
  handleAuth: () => {
    // intentionally left empty
  },
  // updateUiConfigFnc is used to update the UI configuration
  // eslint-disable-next-line no-empty-pattern
  updateUiConfigFnc: ({}: IUpdateUiConfigFnc) => {
    // intentionally left empty
  },
  refreshTokenIfNeeded: () => {
    return Promise.resolve();
  }
});

export function useAuth() {
  const [authDetails, setAuthDetails] = useState<AuthDetails>(initialState);
  const [isRefreshingToken, setIsRefreshingToken] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleAuth = ({ payload }: { payload: any }) => {
    switch (payload.event) {
      case 'signIn':
      case 'cognitoHostedUI':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: payload.data.getSignInUserSession()
        }));
        break;
      case 'signInFailure':
      case 'tokenRefresh_failure':
      case 'signOut':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: null
        }));
        break;
      default:
    }
  };

  const updateUiConfigFnc = ({ payload }: IUpdateUiConfigFnc) => {
    if (payload.event === 'updateUiConfig') {
      setAuthDetails((prev) => ({
        ...prev,
        uiConfigs: { ...prev.uiConfigs, ...payload.data }
      }));
    }
  };

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: userData.getSignInUserSession()
      }));
    } catch (error) {
      console.log('Not signed in');
      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: null
      }));
    }
  };

  const refreshToken = async (cognitoUser: CognitoUser, retryCount = 0) => {
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
      setIsRefreshingToken(false); // Reset token refreshing state
      if (err) {
        console.error('Error refreshing token: ', err);
        if (retryCount < MAX_RETRY) {
          setTimeout(() => refreshToken(cognitoUser, retryCount + 1), RETRY_DELAY);
        } else {
          console.error('Max retries reached. Unable to refresh token.');
          handleSessionExpiry(err);
        }
      } else {
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: session
        }));
      }
    });
  };

  const refreshTokenIfNeeded = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const expiresIn = currentSession.getIdToken().getExpiration() - Math.floor(Date.now() / 1000);
      if (expiresIn < 300) {
        // Refresh if the token expires in less than 5 minutes
        setIsRefreshingToken(true);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await refreshToken(cognitoUser);
      } else if (
        currentSession &&
        authDetails &&
        authDetails.userSession &&
        currentSession.getIdToken().getJwtToken() !==
          authDetails.userSession.getIdToken().getJwtToken()
      ) {
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: currentSession
        }));
      }
    } catch (error) {
      console.error('Error checking token expiration: ', error);
    }
  };

  const handleSessionExpiry = (err: any) => {
    console.log('Session expired. Redirecting to login page...');
    navigate('sessionExpired');
    setAuthDetails((prev) => ({
      ...prev,
      isLoading: false,
      userSession: null
    }));
    handleAuth({ payload: { event: 'tokenRefresh_failure', data: err } });
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      refreshTokenIfNeeded();
    }
  };

  useEffect(() => {
    const MINUTE_MS = insightsConfig.amplify.refreshTokenInterval * 60000;

    Hub.listen('auth', handleAuth);
    getUser();

    const intervalId = setInterval(() => {
      refreshTokenIfNeeded();
    }, MINUTE_MS);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      Hub.remove('auth', handleAuth);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { authDetails, handleAuth, updateUiConfigFnc, refreshTokenIfNeeded, isRefreshingToken };
}

export { AuthContext };
