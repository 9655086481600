import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import axios from 'api-axios';
import insightsConfig from 'config/config';
import { FilterContext } from 'hooks/useFilters';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendErrorsToCW } from 'utils/apiService';

import SavedFiltersTable from './SavedFiltersTable';
import './profile-icon.scss';

export function ViewSavedFilters({ handleClose }: any) {
  const [savedFilters, setSavedFilters] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState('');
  const { storageKey } = useContext(FilterContext) ?? '';
  const navigate = useNavigate();

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4
  };

  useEffect(() => {
    if (showDeleteAlert) {
      const timer = setTimeout(() => {
        setShowDeleteAlert('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showDeleteAlert]);

  const applyFilter = (filter: { filters: string; insights_url: any }) => {
    handleClose();
    localStorage.setItem(storageKey as string, filter.filters);
    if (filter.insights_url === window.location.pathname) {
      window.location.reload();
    } else {
      navigate(`../${filter.insights_url}`, { replace: true, state: undefined });
    }
  };

  const getSavedFilters = async () => {
    setLoading(true);
    const url = `${insightsConfig.baseURL.saveFiltersAPI}/ViewSavedFilters`;
    try {
      const reqBody = { user_id: storageKey };
      const savedFiltersResponse = (await axios.post(url, reqBody)) as any;
      setSavedFilters(savedFiltersResponse?.response as any);
    } catch (err: any) {
      sendErrorsToCW(err, err.stack, storageKey, [], url);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavedFilters();
  }, [storageKey]);

  return (
    <Box sx={ModalStyle}>
      <IconButton className="CloseButton" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Bookmarks</Typography>
        {showDeleteAlert &&
          (showDeleteAlert === 'success' ? (
            <p className="fadeIn alertMessage">Bookmark Deleted</p>
          ) : (
            <p className="fadeIn alertMessage">Failed to Delete Bookmark</p>
          ))}
      </div>

      <SavedFiltersTable
        filters={savedFilters}
        getSavedFilters={getSavedFilters}
        applyFilter={applyFilter}
        storageKey={storageKey}
        isLoading={loading}
        setShowDeleteAlert={setShowDeleteAlert}
      />
    </Box>
  );
}
