import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Backdrop, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserSvg from 'assets/user-3-line.svg';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getProfileName } from '../../utils';
import ConfirmationDialog from '../confirmation-dialog';
import { ViewSavedFilters } from './ViewSavedFilters';
import './profile-icon.scss';

function ProfileIcon({ userDetails }: { userDetails: any }) {
  const { userImageUrl } = userDetails;
  const profileName = getProfileName(userDetails);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const handleModalClose = () => {
    setOpenFilterModal(false);
  };

  const handleModalOpen = () => {
    setOpenFilterModal(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    handleClose();
    setDialogOpen(true);
  };

  const openBookmarksModal = () => {
    handleClose();
    handleModalOpen();
  };

  const navigate = useNavigate();
  const handleDialogClose = (agree = false) => {
    setDialogOpen(false);
    if (agree) {
      navigate('/logOut', { replace: true });
    }
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className="image-circle">
          {userImageUrl ? (
            <img
              className="image-url"
              src={userImageUrl}
              alt="userIcon"
              width="30px"
              height="30px"
            ></img>
          ) : (
            <img
              className="image-icon"
              src={UserSvg}
              alt="userIcon"
              width="30px"
              height="30px"
            ></img>
          )}
        </div>
        <span className="profile-name-text" title={profileName}>
          {profileName}
        </span>
        <KeyboardArrowDownIcon className="down-caret" />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '173px',
            background: '#FFFFFF',
            boxShadow: '0px 10px 20px rgba(32, 8, 102, 0.15)'
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Link className="menu-link" to={'/profile'}>
            My Profile
          </Link>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <Link className="menu-link" to={'/help'}>
            Help and support
          </Link>
        </MenuItem>
        <MenuItem onClick={() => openBookmarksModal()}>Bookmarks</MenuItem>
        <MenuItem onClick={handleDialogOpen}>Sign out</MenuItem>
      </Menu>
      <ConfirmationDialog
        title={'Sign out'}
        content={'Are you sure you want to sign out now?'}
        actionYes={'Yes'}
        actionNo={'Cancel'}
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openFilterModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100
        }}
      >
        <ViewSavedFilters handleClose={handleModalClose} />
      </Modal>
    </div>
  );
}

export default ProfileIcon;
