import axios from 'api-axios';
import insightsConfig from 'config/config';
import { useContext, useEffect, useState } from 'react';
import { sendErrorsToCW } from 'utils/apiService';

import { FilterContext } from './useFilters';

export function useMachineHealthAPI(Customer: string, refreshData: boolean) {
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState<any>();
  const [error, setError] = useState(false);
  const { storageKey } = useContext(FilterContext) ?? {};

  useEffect(() => {
    const fetchMachineHealthData = async () => {
      setIsLoading(true);
      setError(false);
      const url = `${insightsConfig.baseURL.machineHealthInsightsAPI}/CustomerInsight`;
      try {
        const reqBody = { Customer };
        const response = await axios.post(url, reqBody);
        setApiResponse(response);
      } catch (err: any) {
        sendErrorsToCW(err, err.stack, storageKey, [], url);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMachineHealthData();
  }, [Customer, refreshData]);

  return { isLoading, apiResponse, error };
}

export function useProductConfig() {
  const [isLoading, updateLoader] = useState<boolean>(true);
  const [error, isError] = useState<boolean>(false);
  const [productConfig, setConfig] = useState<any>();
  const [products, setProducts] = useState<any>();
  const { storageKey } = useContext(FilterContext) ?? {};

  useEffect(() => {
    const fetchProductConfig = async () => {
      updateLoader(true);
      isError(false);
      setConfig(null);
      const url = `${insightsConfig.baseURL.customerProductConfigurationAPI}/ProductConfig`;
      try {
        const response = (await axios.get(url)) as any;
        setConfig(response?.product_configuration ?? []);
        setProducts(response?.products_from_job_details ?? []);
      } catch (err: any) {
        sendErrorsToCW(err, err.stack, storageKey, [], url);
        isError(true);
      } finally {
        updateLoader(false);
      }
    };
    fetchProductConfig();
  }, []);

  return { isLoading, productConfig, products, error };
}

export function useMissingProductConfigAPI() {
  const [showMissingProductsbadge, setMissingProductsBadge] = useState<boolean>(false);
  const { storageKey } = useContext(FilterContext) ?? {};

  useEffect(() => {
    const fetchProductConfig = async () => {
      const url = `${insightsConfig.baseURL.customerProductConfigurationAPI}/ProductConfigMissing`;
      try {
        const response = (await axios.get(url)) as any;
        setMissingProductsBadge(Object.keys(response?.missing_products).length > 0);
      } catch (err: any) {
        sendErrorsToCW(err, err.stack, storageKey, [], url);
        setMissingProductsBadge(false);
      }
    };
    fetchProductConfig();
  }, []);

  return { showMissingProductsbadge };
}

export function useMachineConfig(Customer: string, Machine: string) {
  const [isLoading, updateLoader] = useState<boolean>(true);
  const [error, isError] = useState<boolean>(false);
  const [customerConfig, setConfig] = useState<any>();
  const { storageKey } = useContext(FilterContext) ?? {};

  useEffect(() => {
    const fetchProductConfig = async () => {
      updateLoader(true);
      isError(false);
      setConfig(null);
      const url = `${insightsConfig.baseURL.machineHealthInsightsAPI}/ProductConfiguration`;
      try {
        const reqBody = {
          Customer,
          Machine,
          Role: 'ProductConfiguration'
        };
        const response = await axios.post(url, reqBody);
        setConfig(response);
      } catch (err: any) {
        sendErrorsToCW(err, err.stack, storageKey, [], url);
        isError(true);
      } finally {
        updateLoader(false);
      }
    };
    fetchProductConfig();
  }, [Customer, Machine]);

  return { isLoading, customerConfig, error };
}
