import CloseIcon from '@mui/icons-material/Close';
import InspectionSvg from 'assets/Frame.svg';
import ProductionSvg from 'assets/Group 10531.svg';
import QualitySvg from 'assets/Group 10533.svg';
import ExportSvg from 'assets/export.svg';
import machineIcon from 'assets/machine.svg';
import JobsSvg from 'assets/remix-icons/line/business/profile-line-2.svg';
import MenuIcon from 'assets/remix-icons/line/system/menu-4-line.svg';
import WeightsSvg from 'assets/weights.svg';
import insightsConfig from 'config/config';
import React from 'react';
import { NavLink } from 'react-router-dom';

import ProductBadge from './ProductsBadge';
import './leftnav.scss';

interface NavConfig {
  path: string;
  text: string;
  imgSrc?: string;
  altText: string;
  hasBadge?: boolean;
}

const navLinks: NavConfig[] = [
  {
    path: insightsConfig.featureToggles.isEnableMultipleTabs
      ? '/production/production_company_view'
      : '/',
    text: 'Production',
    imgSrc: ProductionSvg,
    altText: 'Production'
  },
  {
    path: insightsConfig.featureToggles.isEnableMultipleTabs
      ? '/quality/quality_company_view'
      : '/quality',
    text: 'Quality',
    imgSrc: QualitySvg,
    altText: 'Quality'
  },
  ...(insightsConfig.featureToggles.isEnableWeightsDashboard
    ? [
        {
          path: insightsConfig.featureToggles.isEnableMultipleTabs
            ? '/weights/weights_company_view'
            : '/weights',
          text: 'Weights',
          imgSrc: WeightsSvg,
          altText: 'Weights'
        }
      ]
    : []),
  {
    path: '/jobs',
    text: 'Jobs',
    imgSrc: JobsSvg,
    altText: 'Jobs'
  },
  { path: '/inspections', text: 'Inspections', imgSrc: InspectionSvg, altText: 'Inspections' },
  {
    path: '/productconfiguration',
    text: 'Product Configuration',
    altText: 'Product Configuration',
    hasBadge: true
  },
  { path: '/exportdata', text: 'Exports', imgSrc: ExportSvg, altText: 'Export Data' },
  { path: '/machinehealth', text: 'Machines', imgSrc: machineIcon, altText: 'Machines' }
];

interface Props {
  handleDrawerToggle?: () => void;
}

function LeftNav({ handleDrawerToggle }: Props) {
  const drawerToggleFnc = handleDrawerToggle
    ? handleDrawerToggle
    : () => {
        return;
      };

  const isCustomActive = (tab: string) => {
    const path = window.location.pathname;
    const prodLinks = [
      `/${tab}/${tab}_company_view`,
      `/${tab}/${tab}_location_view`,
      `/${tab}/${tab}_line_view`
    ];
    return prodLinks.includes(path);
  };

  return (
    <>
      <div className="respon-icon-bar">
        <div className="respon-bar">
          <img onClick={handleDrawerToggle} className="ham-menu" src={MenuIcon} alt="clientLogo" />
          <CloseIcon onClick={handleDrawerToggle} />
        </div>
      </div>

      <div className="icon-bar">
        <nav>
          <ul onClick={drawerToggleFnc}>
            {navLinks.map((link, index) => (
              <li key={index}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    (
                      insightsConfig.featureToggles.isEnableMultipleTabs
                        ? isCustomActive(link.text.toLowerCase()) || isActive
                        : isActive
                    )
                      ? 'active-icon'
                      : ''
                  }
                >
                  <div className="icon">
                    {link.hasBadge ? (
                      <ProductBadge />
                    ) : (
                      <img src={link.imgSrc} alt={link.altText} />
                    )}
                    <div className="icon-text">{link.text}</div>
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default LeftNav;
