import { useMediaQuery } from '@mui/material';
import { Amplify } from 'aws-amplify';
import ExpiredSignInAgain from 'pages/expired_sign_out';
import React, { Suspense, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Route, Routes, matchPath, useLocation } from 'react-router-dom';

import './App.scss';
import { updateAuthDetails } from './api-axios';
import CognitoLogIn from './components/cognito-login';
import CognitoLogOut from './components/cognito-logout';
import ResponsiveDrawer from './components/drawer/drawer';
import HeaderBar from './components/header-bar';
import LeftNav from './components/leftnav';
import Loading from './components/loading';
import aws_config from './config/aws_exports';
import insightsConfig from './config/config';
import { AuthContext, useAuth } from './hooks/useAuth';
import { FilterContext } from './hooks/useFilters';
import NotFound from './pages/error/not-found';
import HelpSupport from './pages/help_support';
import SignInAgain from './pages/sign_out';
import { getKeyForStorage } from './utils';

const Production = React.lazy(() => import('./pages/production'));
const Quality = React.lazy(() => import('./pages/quality'));
const Jobs = React.lazy(() => import('./pages/jobs'));
const MultipleInspections = React.lazy(() => import('./pages/multiple-insp/multiple-insp'));
const Inspection = React.lazy(() => import('./pages/inspection/inspection'));
const MachineHealth = React.lazy(() => import('./pages/machine_health/machine-health'));
const ProductConfig = React.lazy(() => import('./pages/productConfig/product-config'));
const ExportData = React.lazy(() => import('./pages/export/export-data'));
const RedirectToActualUrl = React.lazy(() => import('./pages/urlredirection/url-redirection'));
let WeightsDashboard: React.LazyExoticComponent<() => JSX.Element>;
if (insightsConfig.featureToggles.isEnableWeightsDashboard) {
  WeightsDashboard = React.lazy(() => import('./pages/weights'));
}
// const InspectionTest = React.lazy(() => import('./pages/multiple-insp/inspectiontest'));

const InspectionDetails = React.lazy(() => import('./pages/inspection/inspectionDetails'));
Amplify.configure(aws_config);

function App() {
  const { authDetails, refreshTokenIfNeeded, updateUiConfigFnc, handleAuth } = useAuth();
  const location = useLocation();
  const TRACKING_ID = insightsConfig.GoogleAnalytics.GA_ID;
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      cookieDomain: insightsConfig.amplify.cookieDomain,
      cookieFlags: 'samesite=None;Secure'
    }
  });
  const {
    userSession,
    isLoading,
    uiConfigs: { showLeftNav }
  } = authDetails;
  const storageKey = getKeyForStorage(authDetails);
  updateAuthDetails(authDetails);
  const memoUpdateUiConfigFnc = useCallback(updateUiConfigFnc, []);
  const { pathname } = useLocation();
  const { isCognitoHostedUI, identityProvider } = insightsConfig.amplify;

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.href
    });
    refreshTokenIfNeeded();
  }, [location]);

  if ((userSession !== null && !isLoading) || matchPath(pathname, '/signOut')) {
    const originalUrl = sessionStorage.getItem('originalUrl');
    if (originalUrl) {
      sessionStorage.removeItem('originalUrl');
      window.location.assign(originalUrl);
      return null;
    }
    return (
      <AuthContext.Provider
        value={{
          authDetails,
          handleAuth,
          updateUiConfigFnc: memoUpdateUiConfigFnc,
          refreshTokenIfNeeded
        }}
      >
        <FilterContext.Provider value={{ storageKey }}>
          <div className="App">
            <header className="App-header">
              <HeaderBar
                userDetails={userSession?.idToken?.payload}
                handleDrawerToggle={handleDrawerToggle}
              />
            </header>
            {isMobile && (
              <ResponsiveDrawer drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            )}

            {showLeftNav && !isMobile ? (
              <div className="App-leftnav">
                <LeftNav />
              </div>
            ) : (
              <></>
            )}
            <section className="App-body">
              <div className={showLeftNav ? 'App-content' : 'App-content App-content-full'}>
                <Suspense fallback={<div></div>}>
                  <Routes>
                    {insightsConfig.featureToggles.isEnableWeightsDashboard &&
                      (!insightsConfig.featureToggles.isEnableMultipleTabs ? (
                        <Route path="/weights" element={<WeightsDashboard />} />
                      ) : (
                        <Route path="/weights/:weightsId" element={<WeightsDashboard />} />
                      ))}
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/multiple-insp" element={<MultipleInspections />} />
                    <Route path="/machinehealth" element={<MachineHealth />} />
                    <Route path="/productconfiguration" element={<ProductConfig />} />
                    <Route path="/exportdata" element={<ExportData />} />
                    <Route path="/inspections" element={<Inspection />}>
                      <Route path=":inspectionId" element={<InspectionDetails />} />
                    </Route>
                    <Route path="/help" element={<HelpSupport />} />
                    <Route path="/signOut" element={<SignInAgain />} />
                    <Route path="/sessionExpired" element={<ExpiredSignInAgain />} />
                    <Route path="/shared_url/:sharedUrlId" element={<RedirectToActualUrl />} />
                    {!insightsConfig.featureToggles.isEnableMultipleTabs ? (
                      <>
                        <Route path="/gsaiLogin" element={<Navigate replace to={'/'} />} />
                        <Route path="/" element={<Production />} />
                        <Route path="/quality" element={<Quality />} />
                      </>
                    ) : (
                      <>
                        <Route path="/production/:productionId" element={<Production />} />
                        <Route
                          path="/"
                          element={<Navigate replace to={'/production/production_company_view'} />}
                        />
                        <Route
                          path="/gsaiLogin"
                          element={<Navigate replace to={'/production/production_company_view'} />}
                        />
                        <Route path="/quality/:qualityId" element={<Quality />} />
                      </>
                    )}
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/logOut" element={<CognitoLogOut />} />
                  </Routes>
                </Suspense>
              </div>
            </section>

            <footer className="App-footer">
              <div className="footer-div">
                <p>Greyscale AI &#xA9; {new Date().getFullYear()}</p>
                <p>Terms and Conditions</p>
              </div>
            </footer>
          </div>
        </FilterContext.Provider>
      </AuthContext.Provider>
    );
  } else if (authDetails.isLoading) {
    return <Loading />;
  } else {
    return (
      <Routes>
        <Route path="/logOut" element={<CognitoLogOut />} />
        <Route
          path="/gsaiLogin"
          element={
            <CognitoLogIn identityProvider={'GsaiAzureAD'} isCognitoHostedUI={isCognitoHostedUI} />
          }
        />
        <Route
          path="*"
          element={
            <CognitoLogIn
              identityProvider={identityProvider}
              isCognitoHostedUI={isCognitoHostedUI}
            />
          }
        />
      </Routes>
    );
  }
}

export default App;
